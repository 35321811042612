import React from "react";
import "./Produk1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Burgundyimages from "./Burgundy";
import Oliveimages from "./Olive";
import Magentaimages from "./Magenta";
import Violaimages from "../../../Media/Summarecon Bekasi/Viola/Viola";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const Produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Violawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285179856898&text=Halo+Lena%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20Summarecon%20Bekasi%20(Viola)%20https://marketingsummabekasi.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const O8wa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285179856898&text=Halo+Lena%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20Summarecon%20Bekasi%20(Burgundy)%20https://marketingsummabekasi.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Tanamaswa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285179856898&text=Halo+Lena%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20Summarecon%20Bekasi%20(Magenta)%20https:/marketingsummabekasi.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Zlivwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285179856898&text=Halo+Reza%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20Summarecon%20Bekasi%20(Olive)%20https://marketingsummabekasi.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div className="containercard2">
        <div className="card">
          <Slider {...settings}>
            {Violaimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} alt={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Viola</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="white" icon={faMedal} />
              <div className="namalokasi">Subsidi DP</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">5</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="white" icon={faArrowsUpDownLeftRight} />
            <span> 104m²</span>
            <FontAwesomeIcon color="white" icon={faHouse} />
            <span> 124m²</span>
            <FontAwesomeIcon color="white" icon={faBed} />
            <span> 4+1</span>
            <FontAwesomeIcon color="white" icon={faShower} />
            <span> 3+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Violawa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Burgundyimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} alt={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Burgundy</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="white" icon={faMedal} />
              <div className="namalokasi">Siap Huni</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">9</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="white" icon={faArrowsUpDownLeftRight} />
            <span> 104m²</span>
            <FontAwesomeIcon color="white" icon={faHouse} />
            <span> 80m²</span>
            <FontAwesomeIcon color="white" icon={faBed} />
            <span> 3</span>
            <FontAwesomeIcon color="white" icon={faShower} />
            <span> 2</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={O8wa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Magentaimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} alt={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Magenta</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="white" icon={faMedal} />
              <div className="namalokasi">Siap Huni</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">9</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="white" icon={faArrowsUpDownLeftRight} />
            <span> 77m²</span>
            <FontAwesomeIcon color="white" icon={faHouse} />
            <span> 95m²</span>
            <FontAwesomeIcon color="white" icon={faBed} />
            <span> 3+1</span>
            <FontAwesomeIcon color="white" icon={faShower} />
            <span> 3+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Tanamaswa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Oliveimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} alt={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Olive</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="white" icon={faMedal} />
              <div className="namalokasi">Siap Huni</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">9</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="white" icon={faArrowsUpDownLeftRight} />
            <span> 84m²</span>
            <FontAwesomeIcon color="white" icon={faHouse} />
            <span> 97m²</span>
            <FontAwesomeIcon color="white" icon={faBed} />
            <span> 2</span>
            <FontAwesomeIcon color="white" icon={faShower} />
            <span> 2</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Zlivwa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk1;
