import React from "react";
import "./home.scss";
import Navbar from "../Section/Header/navbar.jsx";
import Banner from "../Section/Banner/banner.jsx";
import About from "../Section/About/about.jsx";
import Promo from "../Section/Promo/promo.jsx";
import Promohut from "../Section/promohut/promoanniv.jsx";
import Rumah from "../Section/Rumah/rumah.jsx";
import ScrollToHashElement from "./ScrollToHashElement.js";
import Apartemen from "../Section/Apartemen/apartemen.jsx";
import Ruko from "../Section/Ruko/ruko.jsx";
import Surrounding from "../Section/Surrounding Area/surrounding.jsx";
import Lokasi from "../Section/Lokasi/lokasi.jsx";
import Footer from "../Section/Footer/footer.jsx";

import tombol from "../Media/tombol.webp";

const home = () => {
  const fungsiganteng = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285179856898&text=Halo%20Lena,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Summarecon%20Bekasi%20https://marketingsummabekasi.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="home">
      <ScrollToHashElement />
      <Navbar />
      <Banner />
      <About />
      <Promo />
      <Promohut />
      <Rumah />
      <Apartemen />
      <Ruko />
      <Surrounding />
      <Lokasi />
      <Footer />
      <img
        onClick={fungsiganteng}
        src={tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
    </div>
  );
};

export default home;
